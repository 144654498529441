.imageGallery{
    /* width: 20rem;
    height: 50px; */
    object-fit: contain;
}

.imageGalleryActive{
    /* width: 20rem;
    height: 50px; */
    object-fit: contain;
    opacity: 0.3;
}

.reservationFooter{
    height: 100px;
    width: 100%;
    background-color: red;
}

.reservationPanel{
    height: 100%;
}

.reservationButtonActionsMobile {
    height: 3rem;
}