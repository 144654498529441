.inputPicker{
    background-color: transparent;
    width: 100%;
    border-radius: 4px;
    height: 38px;
    text-align: left;
    border: none;
    font-size: 14px;
    padding-left: 0px;
}
.wrapperPicker{
    background-color: transparent;
    width: 100%;
    border-radius: 4px;
    height: 38px;
    z-index: 0;
}
.inputPicker:focus{
    outline: none;
}
